.c-badge {
  background: #FBB739;
  border-radius: 20px;
  padding: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  align-self: center;
  .c-badge-num {
    color: #FFFFFF;
    font-size: 12px;
  }
}
.c-badge-no-data {
  background: #F5F5F5;
  border-radius: 20px;
  padding: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  align-self: center;
  .c-badge-num-no-data {
    color: #707070;
    font-size: 12px;
  }
}

.status-package {
	font-family: 'Roboto', sans-serif;
	padding: 1px 10px;
	line-height: 20px;
	font-size: 12px;
	border-radius: 4px;
	border: none !important;
	background-color: #fff6e5;
	color: #fbb739;
	font-weight: 500;
	margin: 0;
	white-space: nowrap;
	cursor: pointer;

	&:hover {
		background-color: #faa200;
		color: white;
	}
	&.active {
		background-color: #faa200;
		color: white;
	}
}
