.ticket-detail-container {
  display: flex;
  flex-direction: column-reverse;
  .ticket-infos {
    .ticket-order {
      margin-bottom: 10px;
    }
  }
  .ticket-infos-box {
    .claim-name-heading {
      display: flex;
      flex-direction: column;
      .claim-name-status {

      }
      .claim-btn-archive {

      }
    }
  }
  .ticket-status-box {
    margin-bottom: 15px;
    background-color: white;
    border-radius: 4px;
    padding: 15px;
    .ant-timeline {
      display: flex;
      &.status-length-0, &.status-length-1 {
        .ant-timeline-item {
          .ant-timeline-item-tail {
            display: none;
          }
        }
      }
      .ant-timeline-item {
        flex-grow: 2;
        .ant-timeline-item-tail {
          height: 1px;
          width: calc(100% - 20px);
          border: 1px solid #f0f0f0;
          top: 5px;
          left: 16px;
          display: block;
        }
        .ant-timeline-item-head {

        }
        .ant-timeline-item-content {
          top: 20.142px;
          margin: 0 0 0 0;
        }
      }
    }
    .ticket-status-time {
      color: #707070
    }
  }
  .ticket-feedback {
    .info-group {

    }
  }
  .claim-attachment-list {
    display: flex;
    flex-wrap: wrap;
    .ant-image-mask {
      border-radius: 4px;
    }
    .attachment-item {
      &--file {
        border: 1px solid #ccc;
        border-radius: 4px;
        align-self: center;
        width: 80px;
        height: 80px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 5px;
      }
    }
  }
  .claim-detail-provider-block {
    .supplier-component {
      .supplier-name, .supplier-customer {
        font-size: 14px;
      }
    }
  }
  .claim-detail-order-info-box {
    &__codes {
      display: flex;
      flex-direction: column;
    }
    .order-image-box {
      margin-right: 8px;
      padding-top: 5px;
    }
  }
}

@media only screen and (min-width: 767px) {
  .ticket-detail-container {
    .ticket-infos-box {
      .claim-name-heading {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .ticket-detail-container {
    flex-direction: row;
    .info-group {
      flex-grow: 2;
    }
    .ticket-infos {
      display: flex;
    }
    .ticket-infos-box {
      width: 80%;
      margin-right: 15px;
    }
    .ticket-status-box {
      flex-grow: 1;
      margin-bottom: 0;
      .ant-timeline {
        display: block;
        .ant-timeline-item {
          .ant-timeline-item-tail {
            display: block;
            position: absolute;
            top: 10px;
            left: 4px;
            height: calc(100% - 10px);
            border: none;
            border-left: 2px solid #f0f0f0;
          }
          .ant-timeline-item-content {
            top: -5.858px;
            margin: 0 0 0 26px;
          }
        }
      }
    }
  }
}
