.steps-header {
	z-index: 99;
	background-color: #f7fafc;
	position: fixed !important;
	top: 64px;
	border: transparent;
}
.steps-header-content {
	background-color: #ffffff;
}
.shopping-cart-steps {
	.ant-steps-item-title {
		font-family: 'Roboto', sans-serif;
		font-size: 14px;
		color: #1a1a1a !important;
	}
	.ant-steps-item-wait .ant-steps-item-icon {
		border-color: #bdbdbd;
		color: #bdbdbd;
	}
}
