._service-checkbox {
  .ant-checkbox-checked .ant-checkbox-inner {
    background: #09B8AF;
    border-radius: 2px;
    border: none;
  }
}
._service-checkbox:hover {
  .ant-checkbox-inner {
    border-color: #09B8AF!important;
  }
}
