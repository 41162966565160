
.address-btn-custom {
  background-color: transparent;
    :hover {
      color: rgba(17, 17, 17, 0.7);
      background-color: transparent;
    }
}

._address_count {
  border-radius: 5px;
  background-color: rgba(232, 229, 229, 0.92);
  width: 20px;
  display: inline-block;
  color:orange;
  text-align: center;
}
._address-table-custom thead > tr > th {
  background-color: #F5F5F5;
  border:none;
  height: 24px;
  padding-top:5px;
  padding-bottom: 5px;
  //text-align: center;
}
._address-table-custom thead {
  height: 32px;
}
._address-table-custom tbody > tr > td {
  background-color: white;
  border:none;
  height: 24px;
  //padding: 5px;
  padding-top: 12px;
  padding-bottom: 12px;
}
._address-create-modal {
  .ant-modal-content {
    .ant-modal-header {
      padding-top:12px ;
      padding-bottom: 12px;
    }
  }
}
