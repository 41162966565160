._m24-pagination {
  .ant-pagination-item{
      border-radius: 6px;
  }
  .ant-pagination-item-active {
    background-color: #F9A400;
    //border: 1px solid #CC8700;
    a {
      color:#FFFFFF;
      :active {
        color:white;
      }
      &:hover {
        color: white;
      }
    }
  }
}

._m24-pagination {
  .ant-pagination-item-link {
    border-radius: 6px!important;
  }
  & .ant-pagination-item-active {
    border: none;
  }
}

._m24-pagination {
  .ant-pagination-disabled .ant-pagination-item-link{
    background-color: #EBEBEB;
  ;
  }
}
