.personal-note{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width:300px;
}

.ant-typography-edit-content {
  padding-top:8px !important;
  width: 100%;
  left: 0 !important;
  margin-left: 0 !important;
}