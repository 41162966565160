.splash-container {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.border-card-header{
  box-shadow: 0px 2px 14px #E6E6E6;
  background-color: #FFFFFF;
}
.title-body{
  font-size: 20px;
  color: #1A1A1A;
  font-weight: 500;
}
.icon-empty{
  width: 40px;
  height: 40px;
}

.ant-tabs-nav-list {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #707070
}

.divider-bd-color {
  border-color:#EBEBEB;
}
.main-layout .content {
  background-color: #E0E5E9;
}
.floatIcon{
  position: fixed;
  bottom: 33px;
  right: 20px;
  display: flex;
  align-items: flex-end;
}

@import 'helpers/variables';
@import 'helpers/mixins';
@import "module/common";
@import "module/form";
@import "module/button";
@import "module/table";
@import "module/popup";
@import "module/info-group";
@import "module/rate";
@import "module/badge";
@import "screens/claim";
@import "screens/claim-detail";
@import "module/antd-override";
@import "screens/profile";
