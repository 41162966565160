
	// .clean-filter {
	// 	> span:first-child {
	// 		transform: rotate(-90deg);
	// 	}
	// 	padding-right: 0;
	// 	transition: all .3s cubic-bezier(0.645, 0.045, 0.355, 1);
	// 	box-shadow: none;
	// 	&.ant-btn:not([disabled]):hover {
	// 		color: #1a1a1a;
	// 	}
	// 	&[ant-click-animating-without-extra-node="true"] {
	// 		&::after {
	// 			animation: fadeEffect 3s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.8s cubic-bezier(0.08, 0.82, 0.17, 1);
	// 			opacity: 0.2;
	// 			box-shadow: 0px 0px 0px 3px #CED1F3 !important;
	// 		}	
	// 		& .anticon-redo {
	// 			transition: 1s all ease;
	// 			transform: rotate(270deg) !important;
	// 		}
	// 	}
	// 	&.ant-btn:not([disabled]):active, &:focus{
	// 		color: #707070;
	// 	}
	// }

.filter-card {
	.ant-card-body {
		padding: 0;
	}
}
.transaction-card {
	.ant-card-body {
		padding: 12px
	}
}