.info-group {
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  .info-group-label, .info-group-value {
    display: inline-block;
  }
  .info-group-label {
    margin-right: 5px;
    color: $txt-color-gray4
  }
}

@media only screen and (min-width: 1200px) {
  .info-group {
    display: block;
    .info-group-label, .info-group-value {
      display: block;
    }
  }
}
