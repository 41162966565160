.ant-table-thead > tr > th {
	font-size: $txt-size-h7;
}
.ant-table {
	display: block;
	width: 100%;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: -ms-autohiding-scrollbar;
}

.product-list,
.package-list,
.delivery-request {
	.ant-table {
		.ant-table-thead > tr > th {
			font-family: Roboto;
			font-weight: 500;
			font-size: 14px;
			background-color: #f2f2f2;
			padding: 5px 12px;
			border-bottom: none;
			color: #1a1a1a;
			white-space: nowrap;
			&::before {
				background-color: #c7c7c7 !important;
			}
		}
		tbody {
			td {
				vertical-align: top;
				border-bottom: 1px solid #ebebeb;
				padding: 8px 12px;
			}
		}
	}
}
.package-list {
	.ant-table.ant-table-small {
		.ant-table-thead > tr > th {
			padding: 6px 16px;
			font-weight: 400;
			font-size: 12px;
			line-height: 20px;
			&:first-of-type {
				padding-left: 12px;
			}
		}

		tbody {
			td {
				&:first-of-type {
					padding-left: 12px !important;
				}
				padding: 5px 16px !important;
				border-bottom: none;
			}
		}
	}
}
.order-transaction-list {
	.ant-table-thead > tr > th {
		padding: 5px 16px;
		background-color: #f2f2f2;
	}
	.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
		background-color: #c7c7c7;
	}
	.ant-table-tbody > tr > td {
		padding: 9px 16px;
	}
}

// .packages .ant-table table tbody tr:hover {
// 	td.ant-table-cell { 
// 	background-color: #fafafa;
// 	}
// }

.main-table {
	&.selected-table {
		tr {
			&.active-row {
				& > td {
					background-color: $bg-color-orange4;
					border: none !important;
				}
				&::hover {
					& > td {
						background-color: $bg-color-orange4 !important;
					}
				}
			}
		}
	}
	.ant-table {
		table > tbody > tr {
			&>td {
				&.ant-table-cell {
					vertical-align: middle;
					border-bottom: 1px solid #ebebeb;
					padding: 12px;
					white-space: nowrap;
				}
			}
			&::hover {
				background-color: #fafafa;
			}
		
		}
		&.ant-table-empty {
			table tbody tr {
				&:hover {
					& > td {
						background-color: white !important;
					}
				}
			}
		}
		& .ant-table-thead {
			tr > th {
				&.ant-table-cell {
					font-family: Roboto;
					font-weight: 500;
					font-size: 12px;
					line-height: 20px;
					background-color: $bg-color-gray-tab;
					padding: 6px 12px;
					border-bottom: none;
					color: #1a1a1a;
					white-space: nowrap;
					&::before {
						width: 0 !important;
					}
				}
			}
		}

		& .ant-table-title {
			padding-top: 0;
		}
		table {
			tbody {
				tr {
					&::hover {
						background: red !important;
						td {
							&.ant-table-cell {
								background: red !important;
							}
						}
					}
					&.active-row {
						background-color: $bg-color-pink1;
						&::hover {
							& > td {
								background-color: $bg-color-pink1;
								border: none !important;
							}
						}
					}
					&.ant-table-expanded-row {
						& > td {
							background-color: $bg-color-pink2;
							border: none !important;
						}
					}
					td {
						&.ant-table-cell {

							vertical-align: middle;
							border-bottom: 1px solid #f5f5f5;
							padding: 12px;
						
						}
					}
				}
			}
		}
	}
}
.table-expandable {
	.ant-table-expanded-row {
		& > td {
			padding: 0px auto !important;
			& .ant-table {
				margin: 0 !important;
				.ant-table-thead > tr > th.ant-table-cell {
					background-color: #FFF6E5;
					&::before {
						width: 0;
					}
				}
				td {
					padding: 6px 12px !important;
					background-color: $bg-color-pink2;
					&::hover {
						background-color: unset;
					}
					border-bottom: none !important;
				}
			}
		}
	}
}