.add-supplier {
 & .ant-modal-content {
  & .ant-modal-body {
    padding-top: 0;
    padding-bottom: 12px;
  }

  & .ant-modal-header {
    border-bottom: none;
  }
 
  & .ant-modal-title {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #1a1a1a;
  }

  & .ant-modal-footer {
    padding-bottom: 12px;
  }
 }
}
