.main-layout {
	.sidebar-left::-webkit-scrollbar {
		display: none;
	}
	.sidebar-left {
		height: 100vh;
		position: fixed;
		left: 0;
		z-index: 999;
		overflow-y: auto;
		overflow-x: hidden;
		-ms-overflow-style: none; // Internet Explorer 10+
		scrollbar-width: none; // Firefox
		box-shadow: 6px 0px 16px rgba(112, 112, 112, 0.1);
		& .ant-layout-sider-children {
			overflow-y: scroll;
			overflow-x: hidden;
			scroll-behavior: smooth;
			-ms-overflow-style: none; // Internet Explorer 10+
			scrollbar-width: none; // Firefox
			&::-webkit-scrollbar {
				width: 0px;
				background: transparent;
			}
			& > ul {
				min-height: 100vh;
			}
		}
	}

	.logo {
		height: 32px;
		margin: 16px;
		background: gray;
	}
	.sidebar-left .logo-collapse {
		display: flex;
		height: 32px;
		margin: 16px;
		align-items: center;
		justify-content: center;
	}
	.header {
		background: #fff;
		position: fixed;
		z-index: 99;
		left: 50px;
		right: 0;
		align-items: center;
		padding-left: 16px;
		padding-right: 12px;
		//box-shadow: 0px 2px 14px #E6E6E6;
	}
	.content {
		margin-top: 64px;
		padding: 12px 12px 12px 65px;
		background-color: #EDF3F7;
	}
	.menu-group {
		width: 100%;
		align-items: center !important;
		display: flex !important;
	}
	.menu-group-collapse {
		width: 100%;
		align-items: center !important;
		display: flex !important;
		justify-content: center;
	}
	.ant-menu-item {
		color: #fbb739;
		margin: 0 !important;
		a {
			color: #1a1a1a;
		}
		.ant-menu-title-content {
			.wrapper-icon {
				position: absolute;
				top: 0;
				right: 8px;
				left: 10px;
			}
		}
		&:hover {
			background-color: #fff6e5;
		}
	}
}

.tooltip-custom {
	& .ant-tooltip-inner {
		color: #1a1a1a;
		border-radius: 4px;
		font-weight: 500;
		font-size: 12px;
		line-height: 20px;
		font-family: 'Roboto', sans-serif;
	}
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
	background-color: #FFF6E5;
}
