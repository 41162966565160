.divider-bd-custom {
  border: 1px solid $bd-divider-success-box
}
.faq-modal {
  .ant-modal-header {
    padding-top: 12px;
    padding-bottom: 12px;
    & .ant-modal-title > span {
      color: #1a1a1a !important;
    }
  }
  .ant-modal-footer {
    padding-bottom: 12px;
  }
}