.bt-status{
  border-radius: 24px;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  //display: flex;
  height: 20px;
  left: 12px;
  top: 0;
}
