
.track-order {
    .ant-steps-item-active {
       .ant-steps-item-tail {
            &::after {
                background-color:#FBB739 !important;
            }
        }
    }
    .ant-steps-item-wait {
        .ant-steps-item-tail {
            &::after {
                background-color: #bdbdbd !important;
            }
        }
    }

    & .ant-steps-item-icon {
        width: 10px !important;
        height: 10px !important;
        .ant-steps-icon {
            top: -10.5px !important;
        }
    }
    .ant-steps-item-content {
        text-align: left !important;
    }
  
    .ant-steps-item-tail {
        top: 1.5px !important;
        &::after {
            margin-left: 13.5px !important;
        }
    }
       
    .ant-steps-item-title {
        line-height: 20px !important;
        font-size: 12px !important;
        font-family: 'Roboto', sans-serif !important;
        color: #1A1A1A;
    }
}