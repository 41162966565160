.ant-modal-body {
    padding: 0;
}

.ant-modal-header {
    border-bottom: 1px solid #C7C7C7;
    .ant-modal-title {
        display: flex;
        align-items: center;
    }
}
.ant-modal-close-x {
    line-height: 50px !important;
}