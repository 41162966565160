$box-chat-font-family: 'Roboto', sans-serif;
.box-chat{
  font-family: $box-chat-font-family;
  .alert{
    /* Border */
    padding: 10px;
    &__inner {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      width: 100%;
      border: 1px solid #FFA600;
      box-sizing: border-box;
      border-radius: 4px;
      background: #FFF6E5;
      padding: 5px;
    }
  }
  .box-chat-float-button{
    position: fixed;
    width: 329px;
    height: 34px;
    right: 11px;
    filter: drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.1));
    //transition: width;
    //transition-duration: 0.5s;
    &.expand {
      width: 578px;
    }
  }
  .collapse{
    bottom: 52px;
  }
  .expand{
    bottom: 553px;
  }
  .box-chat-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 6px 12px;
    margin-top: 12px;
    background: #3340B6;
    border-radius: 12px 12px 0px 0px;
    height: 34px;
    &__top-bar {
      display: flex;
      align-items: center;
    }
    &__badge {
      display: inline-block;
      background: #FFFFFF;
      border-radius: 20px;
      color: #3340B6;
      font-style: normal;
      text-align: center;
      line-height: 17px;
      margin-left: 6px;
      padding: 3px 12px;
    }
  }
  .box-chat-list-container{
    display: flex;
    flex-direction: column;
    padding: 0;
    height: 500px;
    background: #FFFFFF;
    position: relative;
    min-width: 330px;
  }
  .list-chat{
    flex:1
  }
  .input-container{

  }
  .box-chat-body {
    background-color: #fff;
    display: flex;
    .box-chat-thread {
      width: 248px;
      border-right: 1px solid #FFD7A5;
      box-sizing: border-box;
      &__filter {
        padding: 10px;
        display: flex;
        &__item {
          flex: 1;
          .ant-select-selector {
            border-radius: 4px;
          }
        }
      }
      &__threads {
        overflow-y: auto;
        overflow-x: hidden;
        height: 448px;
        width: 248px;
        &::-webkit-scrollbar {
          width: 3px;
        }

        //&::-webkit-scrollbar-track {
        //  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        //  border-radius: 10px;
        //}

        &::-webkit-scrollbar-thumb {
          background: rgba(196, 196, 196, 0.6);
          border-radius: 20px;
        }
        .box-chat-thread-item {
          cursor: pointer;
          padding: 10px;
          border-top: 1px solid #FFEED9;
          box-sizing: border-box;
          width: 100%;
          &:hover, &.selected {
            background-color: #FFF6E5;
            font-weight: 400;
          }
          &.read {
            .thread-message {
              color: #707070;
              font-weight: 400;
            }
            .thread-order {
              font-weight: 400;
            }
          }
          .thread-order {
            color: #1A1A1A;
            font-size: 12px;
            font-weight: 500;
            display: flex;
            justify-content: space-between;
            .thread-dot {
              color: #F54255;
              margin-right: 5px;
            }
          }
          .thread-message {
            color: #3340B6;
            font-weight: 500;
            font-size: 12px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .thread-time {
            font-size: 10px;
            color: #BDBDBD;
          }
        }
      }
    }

    .box-chat-conversions-loading, .ant-spin-nested-loading {
      width: 100%;
    }
    .box-chat-messages {
      height: 390px;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 10px;
      display: flex;
      flex-direction: column;
      &::-webkit-scrollbar {
        width: 3px;
      }
      //&::-webkit-scrollbar-track {
      //  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      //  border-radius: 10px;
      //}

      &::-webkit-scrollbar-thumb {
        background: rgba(196, 196, 196, 0.6);
        border-radius: 20px;
      }
      .chat-message-item {
        margin-bottom: 20px;
        max-width: 270px;
        align-self: flex-end;
        &__time-avatar {
          text-align: right;
          margin-bottom: 2px;
          &__time {
            font-size: 10px;
            color: #BDBDBD;
          }
          &__dot {
            color: #BDBDBD;
            margin-right: 10px;
            position: relative;
            top: -2px;
            left: 2px;
          }
          &__avatar {
            width: 14px;
            height: 14px;
            object-position: center center;
            object-fit: cover;
            border-radius: 100%;
            margin-right: 5px;
          }
          &__name {
            font-size: 10px;
            line-height: 12px;
            color: #707070;
            align-self: center;
          }
        }
        &__message {
          display: flex;
          justify-content: flex-end;
          &__attachment-items {
            text-align: right;
            &__inner {
              background-color: #F4F6F8;
              border-radius: 6px 1px 6px 6px;
              display: inline-block;
            }
          }
          &__text {
            max-width: 100%;
            span {
              max-width: 100%;
              overflow: hidden;
              background: #F4F6F8;
              border-radius: 6px 1px 6px 6px;
              color: #1A1A1A;
              padding: 3px 8px;
              display: inline-block;
              a {
                color: #008DF9;
                text-decoration-line: underline;
              }
            }
          }
          &__image {
            box-sizing: border-box;
            width: 48px;
            height: 48px;
            border-radius: 4px;
            margin: 3px;
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5) {
              margin-right: 1.5px;
              margin-bottom: 1.5px;
            }
            &:nth-child(5) {
              margin-right: 0;
            }
          }
          &__video {
            width: 48px;
            height: 48px;
            border-radius: 4px;
            position: relative;
            video {
              width: 100%;
            }
            .video-backdrop {
              position: absolute;
              background-color: rgba(0, 0, 0, .5);
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              z-index: 10;
              border-radius: 4px;
            }
            .video-icon {
              position: absolute;
              color: #fff;
              top: 16px;
              left: 16px;
              font-size: 18px;
              z-index: 11;
            }
          }
          &__file {
            color: #3340B6;
            font-weight: bold;
          }
        }

        &.friend {
          align-self: flex-start;
          .chat-message-item__time-avatar {
            text-align: left;
            display: flex;
            align-items: center;
            &__avatar {
              order: 1;
              align-self: center;
            }
            &__name {
              order: 2;
              align-self: center;
            }
            &__dot {
              order: 3;
              align-self: center;
            }
            &__time {
              order: 4;
              align-self: center;
            }
          }
          .chat-message-item__message {
            justify-content: flex-start;
            &__text {
              text-align: left;
              span {
                background-color: #FFF6E5;
                border-radius: 2px 6px 6px 6px;
              }
            }
            &__image {
              box-sizing: border-box;
              width: 48px;
              height: 48px;
              border-radius: 4px;
              margin: 3px;
              &:nth-child(1),
              &:nth-child(2),
              &:nth-child(3),
              &:nth-child(4),
              &:nth-child(5) {
                border: 1px solid red;
              }
            }
            &__video {

            }
            &__file {

            }
          }
        }
      }
    }

    .chat-input-box {
      border-top: 1px solid #EBEBEB;
      display: flex;
      flex-direction: column;
      &__input-container {
        display: flex;
      }
      &__input {
        resize: none;
        height: 32px;
        overflow-y: auto;
        overflow-x: hidden;
      }
      &__actions {
        margin-left: 10px;
        width: 60px;
        text-align: center;
        margin-top: 5px;
        &__send {
          cursor: pointer;
          color: #707070;
          &:hover {
            color: #3340B6;
          }
        }
        &__send-file {
          cursor: pointer;
          color: #707070;
        }
      }

      &.editing {
        .chat-input-box__actions {
          &__send {
            color: #3340B6;
          }
        }
      }

      &__attachments {
        display: flex;
        flex-wrap: wrap;
        &__item {
          margin: 5px 5px 5px 0;
          box-sizing: border-box;
          width: 56px;
          height: 56px;
          position: relative;
          display: inline-block;
          &:first-child {
            margin-left: 5px;
          }
          &:hover {
            .chat-attachment-remove-icon {
              opacity: 2;
            }
            .attachment-item-backdrop {
              opacity: 1;
            }
          }
          &--image {
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center center;
              border-radius: 4px;
              box-sizing: border-box;
            }
          }

          &--file {
            border: 1px solid #ccc;
            background-color: #ccc;
            padding: 5px;
            overflow: hidden;
            border-radius: 4px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            width: 112px;
            .file-icon {
              margin-right: 5px;
              font-size: 20px;
              background-color: #fff;
              border-radius: 100%;
              width: 30px;
              height: 30px;
              display: inline-block;
              text-align: center;
              padding: 5px 3px 3px 3px;
            }
            .att-file-name {
              line-height: 13px;
              width: 50px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }

          .chat-attachment-remove-icon {
            position: absolute;
            right: 5px;
            top: 5px;
            cursor: pointer;
            color: #fff;
            z-index: 100;
            opacity: 1;
            transition: opacity;
            transition-duration: 0.5s;
          }

          .attachment-item-backdrop {
            opacity: 0;
            background-color: rgba(0, 0, 0, 0.2);
            position: absolute;
            top: 0;
            left: 0;
            z-index: 99;
            width: 100%;
            height: 100%;
            transition: opacity;
            transition-duration: 0.5s;
            border-radius: 4px;
          }
        }
      }
    }
  }
}

.box-chat-modal-preview {
  font-family: $box-chat-font-family;
  .ant-modal-content, .ant-modal-header {
    background-color: #191919;
  }
  .ant-modal-content {
    padding: 0;
  }
  .ant-modal-header {
    border-bottom: none;
  }
  .ant-modal-title {
    font-size: 14px;
    color: #fff;
    padding-left: 16px;
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .ant-modal-body {
    padding: 0 0 54px 0 !important;
  }
  .ant-modal-close-x {
    font-size: 18px;
    color: #fff;
    i {
      position: relative;
      top: 2px;
    }
  }
  .preview-image {
    width: 100%
  }
  .preview-video {
    width: 100%;
    position: relative;
    video {
      width: 100%;
      height: 300px;
    }
    .video-backdrop {
      position: absolute;
      background: linear-gradient(0deg, rgba(51, 51, 51, 0.5), rgba(51, 51, 51, 0.5));
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      border-radius: 4px;
    }
    .video-icon {
      position: absolute;
      top: 107px;
      left: 226px;
      color: #ffffff;
      font-size: 72px;
      cursor: pointer;
      z-index: 11;
    }
  }
}
