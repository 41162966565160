.success-view-custom {
	/* Background/White */
	background: #ffffff;
	/* BG Shadow */
	box-shadow: 0px 2px 14px #e6e6e6;
	border-radius: 4px;

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 16px;
}
.table-custom {
	thead > tr > th {
		background-color: #ebebeb;
		padding: 8px 16px;
	}
}

.break-word {
	word-break: break-word;
}

.whitespace-pre-wrap {
	white-space: pre-wrap;
}
._btn-order-manager {
	border-radius: 4px !important;
	border: 1px solid #ffd7a5 !important;
	height: 42px !important;
	background-color: #ffffff !important;
	color: #ffa634 !important;
	&._btn-order-manager:hover {
		color: white !important;
		background-color: #ffa634 !important;
	}
}

.success-shopping-cart {
	height: fit-content;
	background: #fff6e5;
	box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	display: flex;
	align-items: center;
	flex-direction: column;
	& .ant-result-icon {
		margin-bottom: 0;
	}
}
