.ticket-container {
  .create-ticket-btn {
    justify-content: center !important;
    align-items: center !important;
    padding: 5px 20px !important;
    background: #3340B6 !important;
    border-radius: 4px !important;
    color: #FFFFFF !important;
    border: none;

    &:hover {
      color: #fff;
      box-sizing: border-box;
      box-shadow: 0 0 0 3px #CED1F3;
    }

    &[disabled] {
      background-color: #F5F5F5 !important;
      color: rgba(0, 0, 0, 0.25) !important;
      border-color: #d9d9d9 !important;

      &:hover {
        color: rgba(0, 0, 0, 0.25) !important;
        border-color: #d9d9d9 !important;
        background-color: #f5f5f5 !important;
      }
    }
  }

  .input-layout {
    box-sizing: border-box !important;
    border-radius: 4px !important;

    &::-webkit-input-placeholder {
      font-weight: 400 !important;
      font-family: Roboto, sans-serif;
      color: #BDBDBD !important;
      font-size: 14px !important;
    }

    &.ant-input {
      box-sizing: border-box !important;
      border-radius: 4px !important;
    }

    &.ant-input-affix-wrapper ::-webkit-input-placeholder {
      font-weight: 400 !important;
      font-family: Roboto, sans-serif;
      color: #BDBDBD !important;
      font-size: 14px !important;
    }

    .ant-input-suffix {
      font-size: 14px !important;
    }
  }

  .select-layout {
    .ant-select-selector {
      border: 1px solid #C7C7C7;
      box-sizing: border-box !important;
      border-radius: 4px !important;

      .ant-select-selection-placeholder {
        font-weight: 400 !important;
        font-family: Roboto, sans-serif;
        color: #BDBDBD !important;
        font-size: 14px !important;
      }
    }
  }

  .cancel-create-btn {
    border: 1px solid #C7C7C7 !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;
    font-size: 14px !important;
    font-weight: 400;
    font-family: Roboto, sans-serif;
    color: #1A1A1A;

    &:hover {
      background-color: #707070;
      color: white;
      border: 1px solid #707070;
    }
  }

  .text-area-layout {
    box-sizing: border-box !important;
    border-radius: 4px !important;

    .ant-input {
      box-sizing: border-box !important;
      border-radius: 4px !important;
    }

    &::-webkit-input-placeholder {
      font-weight: 400 !important;
      font-family: Roboto, sans-serif;
      color: #BDBDBD !important;
      font-size: 14px !important;
    }
  }

  .horizontal-line {
    display: block;
    width: 100%;
    border: 1px solid #EBEBEB;
  }

  .create-ticket-form-custom {
    .ant-form-item {
      margin-bottom: 12px;
      .ant-form-item-label {
        margin-right: 16px;
        label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
          content:"";
        }
      }
    }
  }

  .self-center {
    align-self: center;
  }
  .tickets-total-count {
    color: #FFA300;
  }

  .claim-upload-file {

  }
}

.ticket-filter-container {
  .search-btn {
    background: #3340B6;
    border-radius: 4px;
    font-size: 14px !important;
    font-weight: 500;
    font-family: Roboto, sans-serif;
    color: #FFFFFF;
    padding: 4px 29px;
    border: none;
    &:hover {
      color: #fff;
      box-sizing: border-box;
      box-shadow: 0 0 0 3px #CED1F3;
    }
    &:focus{
      border-color: transparent;
    }
  }

  .item {
    margin: 0 5px 0 0;
    padding: 1px 10px ;
    border-radius: 4px;
    align-self: center;
    background: #FFF6E5;
    color: #FBB739;
    &:hover {
      background: #FAA200;
      color: #FFFFFF;
    }
    &-selected {
      background: #FAA200;
      color: #FFFFFF;
    }
  }


  .claim-filter-title {
    display: flex;
    &:after {
      opacity: 1;
      content: "";
      width: calc(100% - 160px);
      border-bottom: 1px solid #EBEBEB;
      display: block;
      align-self: center;
      margin-left: 10px;
    }
  }
  .claim-filter-form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    .claim-filter-form-label {
      margin-bottom: 5px;
    }
    .claim-filter-form-control {
      display: flex;
      flex-wrap: wrap;
    }
  }
}


.ticket-list {
  thead[class*="ant-table-thead"] th{
    padding: 0.32em 1em !important;
    border: none;
    &:before {
      opacity: 0;
    }
  }
  .ticket-status {
    flex-direction: row;
    align-items: center;
    padding: 4px 12px;
    background: #FBB739;
    border-radius: 25px;
    color:#FFFFFF;
  }
  .ticket-refund {
    color: #09B2AA;
  }
  .custom-row-hover:hover td {background: #feefd8 !important;}
}

@media only screen and (min-width: 768px) {
  .ticket-container {
    .claim-filter-title {
      display: flex;

      &:after {
        opacity: 0;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .ticket-container {
    .claim-filter-title {
      display: flex;
      &:after {
        opacity: 0;
      }
    }
    .claim-filter-form-group {
      display: flex;
      flex-direction: row;
      align-items: center;
      .claim-filter-form-label {
        margin-right: 5px;
        margin-bottom: 0;
      }
      .claim-filter-form-control {
        flex-grow: 2;
      }
    }
    .item-selected {
      margin-bottom: 0;
    }
    .item-non-selected {
      margin-bottom: 0;
    }
  }
}

.order-claims-list {
  & th.ant-table-cell {
    background-color: $txt-color-gray3;
    padding: 0.32em 1em !important;
    border: none;
    &::before {
      background-color: #c7c7c7 !important;
    }
  }
}
