
.merchant-card-bottom {
  background-color: #FFFFFF !important;
  border: 1px solid rgba(255, 255, 255, 0.58) !important;
  position: fixed;
  bottom: 0;
  left: 68px;
  width: calc(100% - 80px);
  justify-content: space-between;
  padding-right: 40px;
  padding-bottom: 10px;
  padding-top: 10px;
  align-items: center;
}
.merchant-card-title {
  position: fixed;
  top: 0;
  .ant-card-body {
    padding: 0;
  }
}

.merchant-card-custom {
  background-color: #ffffff !important;
  padding: 0;
  margin-bottom: 12px!important;
}
.button-delete-all {
 height: 40px!important;
 width: 160px;
  :hover {
    border-color: red!important;
    color: red!important;
  }
}

.button-order-custom {
  height: 40px!important;
  width: 160px;
}
