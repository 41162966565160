.faq-modal {
  height: 490px;
  & .ant-modal-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    & .ant-modal-body {
      padding-top: 0;
    }
  }

  & .ant-modal-header {
    border-bottom: none;
  }
  br {
    content: '';
    display: block !important;
    line-height: 16px;
  }
}

.supplier-card-head {
  & .ant-card-body {
    padding: 12px;
  }
  & .ant-card-head {
    padding-left: 12px;
    padding-right: 12px;
    & .ant-card-head-title {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }
}
.supplier-card {
  & .ant-card-head {
    border-bottom: none;
    padding-left: 12px;
    padding-right: 12px;
    & .ant-card-head-title {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }
  & .ant-card-body {
    padding: 12px;
    padding-top: 0;
  }
}