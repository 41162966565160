._search-expand-collapse {
  .ant-collapse-content .ant-collapse-content-box {
    padding: 0;
  }
}

.statusSelected {
  padding: 1px 5px;
  background: #FFFAF3;
  border: 1px solid #F59F32;
  box-sizing: border-box;
  border-radius: 2px;
  margin-right: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}

.status {
  padding: 1px 5px;
  background: #FFFFFF;
  border: 1px solid #C7C7C7;
  box-sizing: border-box;
  border-radius: 2px;
  margin-right: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}

.status:hover {
  padding: 1px 5px;
  background: #FFFAF3;
  /* Warning/BG Hover */
  border: 1px solid #F59F32;
  box-sizing: border-box;
  border-radius: 2px;
  margin-right: 5px;
  margin-bottom: 5px;
  cursor: pointer;

  span {
    color: #F9A400 !important;
  }
;
}


._status-checkbox {
  .ant-checkbox-checked .ant-checkbox-inner {
    background: #09B8AF;
    border-radius: 2px;
    border-color: #09B8AF;

    :hover {
      border-color: #61dafb !important;
    }
  }
}

._status-checkbox:hover {
  .ant-checkbox-inner {
    border-color: #61dafb !important;
  }
}

._status-checkbox {
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      border-color: transparent;

      :hover {
        border-color: #09B8AF !important;
      }
    }
  }
}

._filter-container {
  padding: 6px 12px;
}

._btn-search {
  padding-bottom: 8px !important;
  width: 120px !important;
  margin-bottom: 6px !important;
}


.datePicker-custom {
  height: 32px;
  border-radius: 4px !important;
  //box-sizing: border-box;
  background: #FFFFFF;

  ::placeholder {
    font-weight: 400 !important;
    font-family: Roboto;
    color: #BDBDBD !important;
    font-size: 14px !important;
    //line-height: 22px!important;
  }
}

.input-custom {
  height: 32px;
  border-radius: 4px !important;
  background: #FFFFFF;

  &::-webkit-input-placeholder {
    font-weight: 400 !important;
    font-family: Roboto;
    color: #BDBDBD !important;
    font-size: 14px !important;
    //line-height: 22px!important;
  }
}


.select-custom {
  .ant-select-selector {
    border-radius: 4px !important;
    height: 32px;
    background: #FFFFFF;

    .ant-select-selection-placeholder {
      font-weight: 400 !important;
      font-family: Roboto;
      color: #BDBDBD !important;
      font-size: 14px !important;
    }
  }

}


.refresh-filter-btn:hover {
  color: #1A1A1A;
}
