.freeContainer{
  background-color: #FFF2E2;
  // border: 1px solid #F9A400;
  box-sizing: border-box;
  border-radius: 0px 4px 4px 0px;
  height:100%;
  width: 100%;
  padding: 12px;
}

._note-icon-custom {
  :hover {
    cursor: pointer;
  }
}
._btn-order-custom {
  border-radius: 4px!important;
  border: 1px solid #FFD7A5!important;
  height: 42px!important;
  background-color: #FFFFFF!important;
  color: #FFA634!important;
  &._btn-order-custom:hover {
    color:white!important;
    background-color: #FFA634!important;
  }
}
.notice-warehouse{
  align-items: flex-start;
  justify-content: space-between;
  padding: 8px;
  /* Lighter */
  background: #FFF6E5;
  margin-top: 12px;

  /* Border */
  border: 1px solid #FFA600;
  box-sizing: border-box;
  border-radius: 6px;
}
