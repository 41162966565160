.profile-container {

  .menu-item {
    padding-top: 30px;

    .item {
      margin-bottom: 12px;
      width: 100%;
      //height: 48px;
      align-items: center;
      padding: 12px 8px 12px 16px;
      background: #FFFFFF;
      border-radius: 6px 0 0 6px;
      color: #1A1A1A;
      cursor: pointer;

      &.__current {
        background-color: #3340B6;
        color: #FFFFFF;
      }
    }
  }

  .content-container {
    //padding: 12px;
    background: #FFFFFF;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
  }


  .profile-title {
    padding: 12px 12px 0 12px;
    margin: 0 0 0 -10px;
    width: 100%;

    &.ant-divider-horizontal.ant-divider-with-text-left::before {
      width: 0;
    }

    &.ant-divider-horizontal.ant-divider-with-text-left::after {
      //border: 1px solid #EBEBEB;
      border-color: #EBEBEB;
    }
  }


  .user-info {
    margin-top: -11px;
    //background-color: gray;
    .ant-form-item {
      margin: 12px;
    }

    .ant-form-item-label {
      color: #1A1A1A;
      font-family: Roboto, sans-serif;
      font-weight: 400;
      font-size: 14px;
      align-self: center;
      //margin-right: 40px;
      //label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      //  content: "";
      //}
    }
    .ant-form-item-control {
      margin-left: 40px;
      color: #1A1A1A;
      font-family: Roboto, sans-serif;
      font-weight: 400;
      font-size: 14px;
    }

    .ant-form-item-control-input .ant-form-item-control-input-content {
      .ant-input {
        border: 1px solid #C7C7C7;
        box-sizing: border-box;
        border-radius: 4px;
      }

      .ant-picker {
        border: 1px solid #C7C7C7;
        box-sizing: border-box;
        border-radius: 4px;
      }

      .ant-upload {
        color: #008DF9;

        &:hover {
          color: #1A1A1A;
        }
      }

    }

    .btn-save-info {
      width: 180px;
      margin: 5px 0 0 0;

      border: 1px solid #3340B6;
      box-sizing: border-box;
      border-radius: 4px;
      color: #3340B6;

      &:hover {
        color: #FFFFFF;
        background-color: #3340B6;
        box-sizing: border-box;
        //box-shadow: 0 0 0 3px #c1f3f0;
      }

      &:disabled {
        color: #BDBDBD;
        background-color: #EBEBEB;
        border: none;
      }
    }
  }
}
